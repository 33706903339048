import React from 'react'
import Reveal, { Fade } from 'react-awesome-reveal'
import { fadeInRightShorter } from '../utils/keyframes'

const InfoFifine = () => {
    return (
        <section>
            <div className="container py-lg-5 my-lg-5">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <Reveal keyframes={fadeInRightShorter} duration={1000} className='z-index-2'>
                            <img src={require('../assets/images/info-women.png')} className='info-image' />
                        </Reveal>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <Fade>
                            <div className="info-box">
                                <div className="info-content">
                                    <Reveal keyframes={fadeInRightShorter} duration={1000}>
                                        <h6>Fefine Napkin</h6>
                                    </Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={200} duration={1000}><div className='fw-lighter text-md mb-3'>For women who don’t want to stop</div></Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={400} duration={1000}><p>Cotton based sanitary napkins provide a sustainable alternative to traditional options. Made from natural fibers,  offer gentle care for both women and the environment</p></Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={600} duration={1000}><div className='fw-lighter text-md'>Easy to use, convenience and Biodegradable</div></Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={800} duration={1000}><a href='#' className='btn btn-sm btn-primary mt-3'>Read more</a></Reveal>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoFifine